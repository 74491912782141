import * as React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Page not found">
      <div className="container mb-16">
        <p className="text-xl mb-5">
          The page you were looking for has not been constructed.
        </p>
        <p className="text-xl mb-5">
          Need help with your own construction? Then <a className="underline text-green" href="/contact">contact us!</a>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
